/**
 * 充放电柜静态资源配置
 */

// 订单状态
export const orderStatusOptions = [
    {value:1,label:'未支付'},
    {value:2,label:'已支付,待插入并待关门'},
    {value:3,label:'门已关，未插入'},
    {value:5,label:'已插，待启动'},
    {value:6,label:'充电中'},
    {value:7,label:'放电中'},
    {value:8,label:'待取回'},
    {value:9,label:'已完成'},
    {value:10,label:'支付过期'},
    {value:11,label:'退款中'},
    {value:12,label:'退款失败'},
];

// 订单类型
export const orderTypeOptions = [
    {value:1,label:'充电、适配器检测'},
    {value:2,label:'电池健康与适配器检测'},
];
