<template>
    <section class="container">
      <div class="detail">
        <p> <span>订单编号</span> <b>{{ row.orderId ? row.orderId : '--' }}</b> </p>
        <p> <span>订单状态</span> <b>{{ row.statusName ? row.statusName : '--' }}</b> </p>
        <p> <span>订单费用</span> <b>{{ row.prePayment ? row.prePayment : 0 }}元</b> </p>
        <p> <span>订单类型</span> <b>{{ row.typeName ? row.typeName : '--' }}</b> </p>
        <p> <span>开始时间</span> <b>{{ row.startTimeStr ? row.startTimeStr : '--' }}</b> </p>
        <p> <span>结束时间</span> <b>{{ row.endTimeStr ? row.endTimeStr : '--' }}</b> </p>
        <p> <span>耗电量</span> <b>{{ row.power ? row.power : 0 }}度</b> </p>
        <p> <span>设备号</span> <b>{{ row.chargeDischargeId ? row.chargeDischargeId : '--' }}</b> </p>
        <p> <span>端口号</span> <b>{{ row.portNum ? row.portNum : '--' }}</b> </p>
        <p v-if="orderInfo.status == 2 && orderInfo.chargeDischargePortVO"> <span>端口状态</span> <b>{{ orderInfo.chargeDischargePortVO.portStatusName ? orderInfo.chargeDischargePortVO.portStatusName : '--' }}</b> </p>
        <p> <span>场所名称</span> <b>{{ orderInfo.unitName ? orderInfo.unitName : '--' }}</b> </p>
      </div>
      <!-- 检测结果卡片 -->
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>检测结果</span>
        </div>
        <div>
          <p>电池充电截止电压：{{ orderInfo.batteryVoltage || orderInfo.batteryVoltage == 0 ? orderInfo.batteryVoltage + 'V' : '--' }}</p>
          <p>充电器电压：{{ orderInfo.chargerVoltage || orderInfo.chargerVoltage == 0 ? orderInfo.chargerVoltage + 'V' : '--' }}</p>
          <p>充电器电流：{{ orderInfo.chargerCurrent || orderInfo.chargerCurrent == 0 ? orderInfo.chargerCurrent + 'A' : '--' }}</p>
        </div>
        <div v-if="orderInfo.type == 2">
          <p>电池电量：{{ orderInfo.batteryEnergy || orderInfo.batteryEnergy == 0 ? orderInfo.batteryEnergy + 'AH' : '--' }}</p>
          <p>电池放电截止电压：{{ orderInfo.dischargeStopVoltage || orderInfo.dischargeStopVoltage == 0 ? orderInfo.dischargeStopVoltage + 'V' : '--' }}</p>
        </div>
      </el-card>
      <div class="charts">
        <el-tabs v-model="activeName">
          <el-tab-pane label="折线图" name="first">
            <div id="power"></div>
            <div id="voltage"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  import { getChargeDischargeOrderDetail } from '@/api/chargeDischargeApi.js';
  import { timeChange } from '@/api/publicFun.js';
  let that;
  export default {
    props:['row'],
    data() {
      return {
        activeName:'first',
        orderInfo:{}, // 订单信息
        benefitInfo:[], // 分润信息
        chartsTime:[], // 图表时间数据数组
        powerData:[], // 本次心跳功率图表数据数据
        voltageData:[], // 本次心跳电压图表数据数据
        currentData:[], // 本次心跳电流图表数据数据
        tickEnergyData:[], // 本次心跳用电量数据数组
        totalEnergyData:[], // 总用电量数据数组
        portTempData:[], // 本次心跳舱内温度数据数组
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      that = this;
      this.orderInfo = {...this.row};
      this.init();
    },
  
    methods: {
      async init(){
        await getChargeDischargeOrderDetail({param:this.row.id}).then(res=>{
          if(res.success){
            this.orderInfo = res.data;
            if(this.orderInfo.chargeDischargeDeviceStatusList.length > 0){
              this.orderInfo.chargeDischargeDeviceStatusList.forEach(element => {
                element.createTime = timeChange(element.createTime,'seconds');
                this.chartsTime.push(element.createTime);
                this.powerData.push(element.power ? element.power : 0);
                this.voltageData.push(element.voltage ? element.voltage : 0);
                this.currentData.push(element.current ? element.current : 0);
                this.tickEnergyData.push(element.tickEnergy ? element.tickEnergy : 0);
                this.totalEnergyData.push(element.totalEnergy ? element.totalEnergy : 0);
                this.portTempData.push(element.portTem ? element.portTem : 0);
              });
            }else{
              this.chartsTime = [];
              this.powerData = [];
              this.voltageData = [];
              this.currentData = [];
              this.tickEnergyData = [];
              this.totalEnergyData = [];
              this.portTempData = [];
            }
            this.$nextTick(()=>{
              this.power();
              this.voltage();
            })
          }
        })
      },
      // 功率、电压、电流趋势统计折线图
      power(){
        var powerChartDom = document.getElementById('power');
        let powerChart = echarts.init(powerChartDom);
        console.log(this.chartsTime.length)
        setTimeout(()=>{
          var powerOption = {
            title: {
              text: '心跳信息趋势图',
            },
            tooltip: { // 鼠标悬浮时显示当下数据详情
              trigger: 'axis'
            },
            legend: {
              data: ['功率(W)', '电压(V)', '电流(A)','温度(℃)'],
              show:true,
            },
            xAxis: { // x轴数据
              type: 'category',
              data: that.chartsTime
            },
            yAxis: [
              {
                name: '功率、电压、温度',
                type: 'value'
              },
              {
                name: '电流',
                type: 'value',
              }
            ],
            dataZoom: [
                {
                    type: 'slider', // 使用滑动条形式的缩放组件
                    start: 0, // 左侧在数据窗口范围的起始百分比, 可以根据需要调整
                    end: 100, // 右侧在数据窗口范围的结束百分比, 初始展示全部数据
                    throttle: 100, // 设置触发视图刷新的频率。
                }
            ],
            // dataZoom: [ // 滚动条
            //     {
            //       show: true,
            //       type: 'slider',
            //       backgroundColor: '#97c9f130',
            //       fillerColor: '#95c9fd',
            //       borderColor: '#97c9f130',
            //       showDetail: false,
            //       filterMode: 'empty',
            //       showDataShadow: false,
            //       brushSelect: false, // 是否开启刷选功能
            //       height: 10,
            //       handleSize: 0,
            //       xAxisIndex: [0],
            //       zoomLock: true, // 是否锁定选择区域（或叫做数据窗口）的大小
            //       throttle: 100, // 设置触发视图刷新的频率。
            //       realtime: true,
            //       bottom: '1%',
            //       startValue: 0, 
            //       endValue: 40, // 数据窗口展示数据的条数
            //     }
            // ],
            series: [ // 数据数组
              {
                name:'功率(W)',
                data: this.powerData,
                type: 'line',
                smooth: true,
                symbolSize: 0,
              },
              {
                name:'电压(V)',
                data: this.voltageData,
                type: 'line',
                smooth: true,
                symbolSize: 0,
              },
              {
                name:'电流(A)',
                data: this.currentData,
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                symbolSize: 0,
              },
              {
                name:'温度(℃)',
                data: this.portTempData,
                type: 'line',
                smooth: true,
                symbolSize: 0,
              }
            ],
          };
          powerOption && powerChart.setOption(powerOption);
        },500)
      },
      // 电量趋势
      voltage(){
        var voltageChartDom = document.getElementById('voltage');
        let voltageChart = echarts.init(voltageChartDom);
        setTimeout(()=>{
          var voltageOption = {
            title: {
              text: '电量趋势(单位：AH)',
            },
            tooltip: { // 鼠标悬浮时显示当下数据详情
              trigger: 'axis'
            },
            legend: {
              data: ['心跳耗电量','总耗电量'],
              show:true,
            },
            xAxis: {
              type: 'category',
              data: that.chartsTime
            },
            yAxis: [
              {
                name: '总耗电量',
                type: 'value'
              },
              {
                name: '心跳耗电量',
                type: 'value',
              }
            ],
            dataZoom: [
                {
                    type: 'slider', // 使用滑动条形式的缩放组件
                    start: 0, // 左侧在数据窗口范围的起始百分比, 可以根据需要调整
                    end: 100, // 右侧在数据窗口范围的结束百分比, 初始展示全部数据
                    throttle: 100, // 设置触发视图刷新的频率。
                }
            ],
            series: [
              {
                name:'心跳耗电量',
                data: this.tickEnergyData,
                type: 'line',
                smooth: true,
                symbolSize: 0,
                yAxisIndex: 1,
              },
              {
                name:'总耗电量',
                data: this.totalEnergyData,
                type: 'line',
                smooth: true,
                symbolSize: 0, // 展示的节点数量
              }
            ]
          };
          voltageOption && voltageChart.setOption(voltageOption);
        },500)
      },
    },
  };
  </script>
  <style scoped lang="scss">
  .container{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  /deep/.box-card{
    width: 90%;
    margin:0 auto 20px;
  }
  #power{
    margin-top: 40px;
  }
  .detail{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 14px;
    box-sizing: border-box;
    h3{
        width: 100%;
        height: 70px;
        line-height: 70px;
        text-align: left;
    }
    p{
        line-height: 16px;
        box-sizing: border-box;
        display: flex;
        width: 50%;
        span{
            display: inline-block;
            background: #fafafa;
            border:solid #f0f0f0 1px;
            width: 120px;
            box-sizing: border-box;
            height: 40px;
            line-height: 40px;
            box-sizing: border-box;
        }
        b{
             display: inline-block;
            background: #fff;
             border:solid #f0f0f0 1px;
             box-sizing: border-box;
             height: 40px;
             line-height: 40px;
             flex: 1;
         }
    }
    .address{
      width: 100%;
    }
  }
  .charts{
    width: 90%;
    margin: 0 auto;
    height: 100%;
    flex: 1;
    div{
        width: 100%;
        min-height: 350px;
        margin-bottom: 30px;
    }
  }
  /deep/ .el-tabs{
    display: flex;
    flex-direction: column;
  }
  /deep/ .el-tabs__content{
    flex: 1;
    overflow: auto !important;
  }
  .benefit{
      display: flex;
      flex-direction: column;
      div{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 0 !important;
        height: 40px!important;
        &:nth-child(1){
          background: #fafafa;
        }
        span{
          display: inline-block;
          width: 25%;
          border: solid 1px #f0f0f0;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
      }
    }
  </style>
  